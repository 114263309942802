import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import MetaInfo from "vue-meta-info";
import components from '@/components/index';

/* 滚动伴随动画 */
import 'animate.css/animate.min.css'
import 'wow.js/css/libs/animate.css'

/* bootstrap */
import "@/assets/lib/bootstrap/bootstrap.css";
import "@/assets/lib/bootstrap/bootstrap.bundle.min.js";
// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.css";

/* 样式表 */
import "@/assets/style/index.scss";
// import "@/assets/style/fonts.scss";


/* 平滑滚动 */
import "@/assets/lib/SmoothScroll.min.js";

import { dispatchEventStroage } from '@/hooks/useCommon.js'

createApp(App)
    .use(components)
    .use(router)
    .use(MetaInfo)
    .use(dispatchEventStroage)
    .mount("#app");


export default [
	{
		path: "/:pathMatch(.*)",
		component: () => import("../components/NotFoundComponent.vue" ),
	},
	{
		path: "/",
		redirect: "/home",
		component: () => import( "../views/home" ),
	},
	{
		path: "/home",
		name: "home",
		component: () => import( "../views/home" ),
	},
	{
		path: "/option",
		name: "option",
		component: () => import("../views/OptionView"),
	},
	{
		path: "/option/tone",
		name: "option-tone",
		component: () => import("../views/OptionView/tone"),
	},
	{
		path: "/option/datacenter",
		name: "option-datacenter",
		component: () => import("../views/OptionView/datacenter"),
	},
	{
		path: "/option/ai",
		name: "option-ai",
		component: () => import("../views/OptionView/ai"),
	},
	{
		path: "/news",
		name: "news",
		component: () => import("../views/NewsView"),
	},
	{
		path: "/news/detail",
		name: "news-detail",
		component: () => import( "../views/NewsView/detail"),
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import( "../views/ContactView"),
	},
	{
		path: "/about",
		name: "about",
		component: () => import("../views/AboutView"),
	},
	{
		path: "/servicePattern",
		name: "servicePattern",
		component: () => import("../views/ServicePattern"),
	},
	{
		path: "/Reservation",
		name: "Reservation",
		component: () => import("../views/Reservation"),
	},
];

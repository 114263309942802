
export default [
	{
		path: "/:pathMatch(.*)",
		component: () =>
			import(
				/* webpackChunkName: "NotFoundComponent" */ "../components/NotFoundComponent.vue"
			),
	},
    {
        path: "/home",
        name: "home",
        component: () =>
            import(
                /* webpackChunkName: "home" */ "../views/home"
            ),
    },
	{
		path: "/",
		redirect: "/home",
	},
	{
		path: "/option",
		name: "option",
		component: () =>
			import(/* webpackChunkName: "option" */ "../views/OptionView"),
	},
	{
		path: "/option/tone",
		name: "option-tone",
		component: () =>
			import(/* webpackChunkName: "optionTone" */ "../views/OptionView/tone"),
	},
	{
		path: "/option/datacenter",
		name: "option-datacenter",
		component: () =>
			import(/* webpackChunkName: "optionDatacenter" */ "../views/OptionView/datacenter"),
	},
	{
		path: "/option/ai",
		name: "option-ai",
		component: () =>
			import(/* webpackChunkName: "optionAi" */ "../views/OptionView/ai"),
	},
	{
		path: "/news",
		name: "news",
		component: () =>
			import(/* webpackChunkName: "news" */ "../views/NewsView"),
	},
	{
		path: "/news/detail",
		name: "news-detail",
		component: () =>
			import(/* webpackChunkName: "newsDetail" */ "../views/NewsView/detail"),
	},
	{
		path: "/contact",
		name: "contact",
		component: () =>
			import(/* webpackChunkName: "contact" */ "../views/ContactView"),
	},
	{
		path: "/about",
		name: "about",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/AboutView"),
	},
	{
		path: "/servicePattern",
		name: "servicePattern",
		component: () =>
			import(/* webpackChunkName: "servicePattern" */ "../views/ServicePattern"),
	},
	{
		path: "/Reservation",
		name: "Reservation",
		component: () =>
			import(/* webpackChunkName: "message" */ "../views/Reservation"),
	},

	/* 测试页面 */
	{
		path: "/testhome",
		name: "testhome",
		component: () =>
			import(/* webpackChunkName: "testhome" */ "../views/testhome"),
	},
];
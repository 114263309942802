<template>
	<svg
		class="home-arr"
		version="1.1"
		width="24px"
		height="24px"
		viewBox="0 0 24.0 24.0"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink">
		<g transform="translate(-463.0 -194.0)">
			<g clip-path="url(#i0)">
				<g transform="translate(463.0 194.0)">
					<g transform="translate(0.0 3.0)">
						<path
							fill="currentColor"
							d="M12.6087333,0 L21,8.5 L12.6087333,17 L10.1612805,14.5208333 L14.3752232,10.2527537 L0,10.2530356 L0,6.74696444 L14.3752232,6.74668258 L10.1612805,2.47916667 L12.6087333,0 Z"></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
<style lang="scss" scoped>
.home-arr {
	width: 100%;
	height: 100%;
}
</style>

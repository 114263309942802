<template>
	<nav
		ref="navRef"
		data-screen="banner"
		class="layout-head-nav topnav navbar navbar-expand-lg navbar-light fixed-top"
		:class="[navState.navClassN, navState.collapseShow ? 'nav-coustom-folded' : '']">
		<div class="container-fluid">
			<!-- LOGO -->
			<router-link class="logo-link" to="/home">
				<i class="logo-image fas fa-anchor mr-2"></i>
			</router-link>
			<!-- 折叠按钮 -->
			<button
				class="navbar-toggler collapsed"
				type="button"
				data-toggle="collapse"
				data-target="#navbarColor02"
				aria-controls="#navbarColor02"
				aria-expanded="false"
				aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<!-- 导航列表 -->
			<div class="navbar-collapse collapse collapsing" id="navbarColor02" style="">
				<ul class="navbar-nav ml-auto d-flex align-items-right">
					<template v-for="(nav, i) in navList" :key="i">
						<li class="nav-item" v-if="!nav.children">
							<!-- <a class="nav-link" href="./index.html">11</a> -->
							<router-link class="nav-link" :to="nav.path">
								<span class="blink-arrow d-block d-lg-none"><ArrSvg /></span>
								<span>{{ nav.name }}</span>
								
							</router-link>
						</li>

						<li class="nav-item dropdown" v-else>
							<a
								href="#"
								class="dropdown-toggle nav-link"
								data-toggle="dropdown">
								<span class="blink-arrow d-block d-lg-none"><ArrSvg /></span>
								
								<span>{{ nav.name }}</span>
								<!-- <b class="caret"></b> -->
							</a>

							<!-- 小屏 -->
							<ul class="dropdown-menu agile_short_dropdown" :class="[ navState.collapseShow ? 'show' : '']">
								<li
									class="pb-2"
									v-for="(subNav, Idx) in nav.children" :key="Idx">
									<router-link
										class="nav-link p-0"
										:to="subNav.path">
										{{ subNav.name }}
									</router-link>
								</li>
							</ul>
						</li>
					</template>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script setup>
import { onMounted, reactive, watch } from "vue";
import ArrSvg from "@/components/ArrSvg.vue";
import { wRatio, _isMobile } from '@/hooks/useCommon.js';
import { useRoute } from "vue-router";

const route = useRoute();
const HOME_PATH = '/home'
const DEFAULT_NAV_PATHS = ['/home', '/option/tone', '/option/datacenter', '/option/ai', '/about']
const COMMON_ON_SCROLL = ['/option/tone', '/option/datacenter', '/option/ai','/about']
const navState = reactive({
	menuName: "首页",
	menuClass: "glyphicon glyphicon-menu-down",
	navIndex: 0,
	navCurPath: '',
	homePath: '/home',
	navClassN: 'default_nav',
	collapseShow: false,
	isHome: true,
});

const navList = [
	{ name: "首页", path: "/home", idx: 1 },
	{ name: "服务模式", path: "/servicePattern", idx: 1 },
	{
		name: "解决方案",
		path: "/option",
		idx: 2,
		children: [
			{ name: "低代码", path: "/option/tone", idx: 21 },
			{ name: "数据中台", path: "/option/datacenter", idx: 22 },
			{ name: "人工智能", path: "/option/ai", idx: 23 },
		],
	},
	{ name: "新闻资讯", path: "/news", idx: 3 },
	// { name: "联系我们", path: "/contact", idx: 4 },
	{ name: "关于我们", path: "/about", idx: 5 },
	// { name: "test", path: "/testhome", idx: 6 },
];
// 拍平后的导航项数组
const navflatList = navList.flatMap((item) => (item.children ? item.children : item));
// 监听路由改变
watch(() => route.path, (nPath, oPath) => {
	// 更新当前路由索引
	navState.navIndex = navflatList.find(item => item.path === nPath)?.idx || 999;
	navState.navCurPath = nPath;
	// console.log(navflatList, 'watch-route', navState.navIndex, nPath, DEFAULT_NAV_PATHS.includes(nPath));
	navState.isHome = navState.navIndex === 0 || nPath === HOME_PATH;

	if (navState.navIndex === 0 || DEFAULT_NAV_PATHS.includes(nPath)) {		// 使用默认的nav风格样式(同首页)
		navState.navClassN = 'default_nav'
	} else if (navState.navCurPath === '/news/detail') {	// 复用高亮白底黑字nav风格样式, 但是背景换为透明(特殊)
		navState.navClassN = 'hhai_transparent_nav'
	} else {
		navState.navClassN = 'hhai_nav'		// 使用高亮白底黑字的nav风格样式
	}
 },{ immediate: true });

onMounted(() => {
	window.addEventListener("scroll", onWindowScroll);
	window.addEventListener("scroll", onWindowScrollAllPage);
	menuClick();
	changeCollapse();
});

/* 滚动事件监听 */
function onWindowScroll() {
	// console.log(e, '监听首屏(home)滚动');
	// home页滚动监听处理
	if (navState.isHome) {		
		handlerHomeScroll()
	}else if (navState.navCurPath === '/news/detail') {	
		// 新闻详情页滚动监听处理
		handlerNewsDetailScroll()
	} else if (COMMON_ON_SCROLL.includes(navState.navCurPath)) {
		// 其它
		handlerOptionToneScroll()
	}
}

/* 全部页面监听处理 */
function onWindowScrollAllPage() {
	const html = document.querySelector("html");
	// 滚动距离超出屏幕一半高度: 添加类名 scroll-overflow-screen
	if (html?.scrollTop > html?.clientHeight / 2) {
		document.querySelector("body").className = 'scroll-overflow-screen'
	} else {
		document.querySelector("body").className = ''
	}
}

/* 主页滚动监听处理 */
function handlerHomeScroll() {
	const body = document.querySelector("html");
	const sectionTwo = document.querySelector(".intro-section-comp");
	const scheme = document.querySelector(".scheme");
	const technology = document.querySelector(".technology");
	// const technologyH = $('.technology').outerHeight();
	const news = document.querySelector(".news-comp-wrap");
	
	// 判断: 第二模块解决方案 是否出现在滚动区域
	if (body?.scrollTop > scheme?.offsetTop) {
		navState.navClassN = 'hhai_nav'
	} else {
		navState.navClassN = 'default_nav'
	}
	/* 判断技术展示模块距离顶部高度, 以此改变解决方案模块背景 */
	const num = _isMobile ? 350 : 350;
	const gap = (num * wRatio());

	// if (technology?.offsetTop - body?.scrollTop < (350 * wRatio())) {
	if ( (news?.offsetTop - gap) > body?.scrollTop && body?.scrollTop > technology?.offsetTop -gap ) {
		scheme.style.backgroundColor = '#9391A8';
		technology.style.backgroundColor = '#9391A8';
		news.style.backgroundColor = '#9391A8';
	} else {
		scheme.style.backgroundColor = '#FFFFFF';
		technology.style.backgroundColor = '#FFFFFF';
		news.style.backgroundColor = '#FFFFFF';
	}
}
/* 新闻详情页滚动监听处理 */
function handlerNewsDetailScroll(e) {
	const body = document.querySelector("html");
	// 滚动距离超过50px 导航换色
	if (body?.scrollTop > (50 * wRatio())) {
		navState.navClassN = 'hhai_nav'
	} else {
		navState.navClassN = 'hhai_transparent_nav'
	}
}

/* 低代码解决方案页滚动监听处理 */
function handlerOptionToneScroll(e) {
	const body = document.querySelector("html");
	const toneHeader = document.querySelector(".section-one");
	const toneHeaderH = $('.section-one').outerHeight();

	// 判断: 第一个模块 是否离开视区
	if (body?.scrollTop > (toneHeader?.offsetTop+toneHeaderH - (64 * wRatio()))) {
		navState.navClassN = 'hhai_nav'
	} else {
		navState.navClassN = 'default_nav'
	}
}

const menuClick = () => {
	$('.navbar-collapse.collapse .nav-link').on('click', function (e) {
		if (!$(this).hasClass("dropdown-toggle nav-link")) {
			$(".navbar-collapse.collapse.show").toggleClass("show");
			navState.collapseShow = $(".navbar-collapse.collapse").hasClass('show');
		}
	})
};

function changeCollapse() {
	$('#navbarColor02').on('show.bs.collapse', function () {
		navState.collapseShow = true;
	})
	$('#navbarColor02').on('hide.bs.collapse', function () {
		navState.collapseShow = false;
	})
}
</script>

<style lang="scss">
// $textColor: red;
// $textHoverColor: saddlebrown;
// $navTextColor: #1776e1;
// $navCollapseBg: #3588cb;
// $hover: #7d0000; 
// $itemNavBg: #000243;
// $primary4: #eb6100; 

@import "@/assets/style/mixin.scss";


// 导航颜色定制
// 默认进入
$default: (
    navText: #FFFFFF,
    navCollapseBg: transparent,
    linkHover: #FFFFFF,
    navItemBg: transparent,
	logoImg:'~@/assets/imgs/white-logo.png',
	navIcon: '~@/assets/imgs/icon/nav-toggle-white.png',
	linkTextColor: #FFFFFF,
);

// 滚动到banner以外的白底色上的样式
$hhai: (
    navText: #22222B,
    navCollapseBg: #FFFFFF,
    linkHover: #22222B,
    navItemBg: #FFFFFF,
	logoImg:'~@/assets/imgs/black-logo.png',
	navIcon: '~@/assets/imgs/icon/nav-toggle-black.png'
);


// // 小屏&移动端出现折叠框样式
$small: (
    navText: #FFFFFF,
    navCollapseBg: #22222B,
    linkHover: #FFFFFF,
    navItemBg: #22222B,
	logoImg:'~@/assets/imgs/white-logo.png',
	navIcon: '~@/assets/imgs/icon/nav-toggle-white.png'
);

// // 小屏&移动端出现折叠框样式-滚动到banner以外的白底色上的样式
$smallHhai: (
    navText: #FFFFFF,
    navCollapseBg: #22222B,
    linkHover: #FFFFFF,
    navItemBg: #22222B,
	logoImg:'~@/assets/imgs/white-logo.png',
	navIcon: '~@/assets/imgs/icon/nav-toggle-white.png'
);

.layout-head-nav {
	width: 100%;
	box-sizing: border-box;
	font-family: PingFangSC-Regular, PingFang SC;
	.logo-link{
		line-height: 0;
	}
	.logo-image {
		position: relative;
		width: 7.31rem;
		height: 2.25rem;
		display: inline-block;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 7.31rem;
			height: 2.25rem;
			background: url(map-get($default,logoImg)) no-repeat center center;
			background-size: cover;
		}
	}
	.navbar-nav {
		strong {
			font-size: 15px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
		}
	}
}

//  默认 透明背景||深色背景nav样式
.default_white_style{
	@include navAcitveStyle(
		map-get($default,navText),
		map-get($default,navCollapseBg),
		map-get($default,linkHover),
		map-get($default,navItemBg),
	);

	.blink-arrow{
		// width: 1.5rem;
		// height: 1.5rem;
		// display: inline-block;
		// color: green !important;
		opacity: 0;
	}


	.logo-image::before{
		background: url(map-get($default,logoImg)) no-repeat center center;
		background-size: cover;
	}
	.dropdown .dropdown-toggle {
		@include arrowLine(map-get($default,navText));
	}
	.dropdown.show .dropdown-toggle {
		@include arrowLine(map-get($default,navText), top);
	}
	// 折叠按钮icon
	.navbar-toggler .navbar-toggler-icon {
		background-image: url(map-get($default,navIcon));
	}
}

// 默认 nav透明背景（黑色字体与图标）:  对应导航样式
.layout-head-nav{
	@extend .default_white_style;
}


// nav 白底色（黑色字体与图标）:  对应导航样式
.hhai_nav.layout-head-nav{
	background: #fff;
	@include navAcitveStyle(
		map-get($hhai,navText),
		map-get($hhai,navCollapseBg),
		map-get($hhai,linkHover),
		map-get($hhai,navItemBg),
	);
	.logo-image::before{
		background: url(map-get($hhai,logoImg)) no-repeat center center;
		background-size: cover;
	}
	.dropdown .dropdown-toggle {
		@include arrowLine(map-get($hhai,navText));
	}
	.dropdown.show .dropdown-toggle {
		@include arrowLine(map-get($hhai,navText), top);
	}
	// 折叠按钮icon
	.navbar-toggler-icon {
		background-image: url(map-get($hhai,navIcon));
	}
}

// nav 白底色（黑色字体与图标）:  对应导航样式
.hhai_transparent_nav.layout-head-nav{
	@include navAcitveStyle(
		map-get($hhai,navText),
		map-get($default,navCollapseBg),
		map-get($hhai,linkHover),
		map-get($default,navItemBg),
	);
	.logo-image::before{
		background: url(map-get($hhai,logoImg)) no-repeat center center;
		background-size: cover;
	}
	.dropdown .dropdown-toggle {
		@include arrowLine(map-get($hhai,navText));
	}
	.dropdown.show .dropdown-toggle {
		@include arrowLine(map-get($hhai,navText), top);
	}
	// 折叠按钮icon
	.navbar-toggler-icon {
		background-image: url(map-get($hhai,navIcon));
	}
}

/* ----------------------- <992屏幕(pc&移动端) ----------------------- */

@media (max-width: 991.98px) {
	// <992屏幕(pc&移动端): 对应导航样式(固定的)
	.layout-head-nav.topnav.navbar.navbar-expand-lg.navbar-light{
		.navbar-collapse.collapse .nav-item{
			background: map-get($small,navItemBg);
		}
		.navbar-collapse.collapse.show{
			background: map-get($small,navCollapseBg);
		}
	}
	
	
	// <992屏幕(pc&移动端): nav白底色（黑色字体与图标）:  对应导航样式
	.hhai_nav.layout-head-nav{
		background: #fff;
		@include navAcitveStyle(
			map-get($smallHhai,navText),
			map-get($smallHhai,navCollapseBg),
			map-get($smallHhai,linkHover),
			map-get($smallHhai,navItemBg),
		);
	}
	// 导航折叠框内样式（内外背景一致, 使用白底字体）
	.layout-head-nav.topnav.navbar.navbar-expand-lg.navbar-light{
		height: auto;
		display: block;
		.navbar-collapse.collapse.show{
			margin-top: 2.2rem;
		}
		.nav-item{
			text-align: right;
			padding-top: 2.5rem;
			padding-right: 1rem;
			margin-left: 0;
			.nav-link{
				padding-top: 0;
				padding-bottom: 0;
				font-size: 1.25rem;
				text-decoration: underline solid #FFFFFF 1px;
				text-underline-offset: 5px;
				display: flex;
				justify-content: space-between;
			}
			.blink-arrow{
				width: 1.5rem;
				height: 1.5rem;
				display: inline-block;
				color: #FFFFFF;
				opacity: 1;

			}
			.dropdown-toggle.nav-link{
				&:after{
					content: none !important;
				}
			}
		}
		.nav-item .dropdown-menu{
			width: 100%;
			padding-top: .3rem;
			padding-right:0;
			>li{
				padding-top: .7rem;
				padding-bottom: 0 !important;
				.nav-link{
					font-size: .88rem;
					text-align: right;
					display: block;
				}
			}
		}
	
		@include navAcitveStyle(
			map-get($small,navText),
			map-get($small,navCollapseBg),
			map-get($small,linkHover),
			map-get($small,navItemBg),
		);
		&.nav-coustom-folded{
			background: #22222B;
			height: 100%;
			transition: height .5s;
			// logo
			.logo-image::before{
				background: url(map-get($small,logoImg)) no-repeat center center;
				background-size: cover;
			}		
			// 折叠按钮icon
			.navbar-toggler-icon {
				width: 1.5rem;
				height: 1.5rem;
				background-image: url('~@/assets/imgs/icon/nav-close.png');
			}
		}
	}
}
















.app-small .layout-head-nav .logo-image{
	width: 7.88rem;
	height: 1.88rem;
	&:before{
		width: 7.88rem;
		height: 1.88rem;
	}
}

/* 页面大于992 */
@media (min-width: 992px) {
	.layout-head-nav { }
}

/* 当页面宽度大于320px小于992px */
@media screen and (min-width: 300px) and (max-width: 992px) { }

</style>

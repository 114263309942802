import { onMounted } from "vue";
import router from "@/router";

const WEEKS = new Array("周日", "周一", "周二", "周三", "周四", "周五", "周六");

const CLIENT_W = document.documentElement.clientWidth;
const CLIENT_H = document.documentElement.clientHeight;

/* 移动设备判断 */
export const _isMobile = navigator.userAgent.match(
	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
// const _isMobile = navigator.userAgent.match(
// 	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
// );

/* 屏幕像素比 */
export function getPixelRatio() {
	let ctx = document.createElement("canvas").getContext("2d");
	let dpr = window.devicePixelRatio || 1;
	let bsr =
		ctx.webkitBackingStorePixelRatio ||
		ctx.mozBackingStorePixelRatio ||
		ctx.msBackingStorePixelRatio ||
		ctx.oBackingStorePixelRatio ||
		ctx.backingStorePixelRatio ||
		1;
	return dpr / bsr;
}

// 基准宽度比
export function wRatio(opts) {
	if (_isMobile) {
		return CLIENT_W / (375 * getPixelRatio());
	} else {
		return CLIENT_W / 1440;
	}
}

/* js媒体查询 */
export function screenMediaQuery() {
	onMounted(() => {
		/* app入口处添加类:  1. 设备判断(pc/移动), 2.媒体查询大小屏分辨,分界为992px (app-big / app-small) */
		const className = _isMobile ? "mobile" : "pc";
		// const mqList = window.matchMedia("(min-width: 992px)");
		const mqList = window.matchMedia("(min-width: 992px)");
		// let MiddleEnd = window.matchMedia("(max-width: 992px)");
		// let MiddleStart = window.matchMedia("(min-width: 592px)");
		function widthChangeCallback(mqList) {
			console.log('mqList', mqList);
			if (mqList.matches) {
				document.querySelector(
					"html"
				).className = `${className} app-big`;
			} else {
				document.querySelector(
					"html"
				).className = `${className} app-small`;
			}
			sessionStorage.setItem("_isMobile", _isMobile ? 1 : 0);
			sessionStorage.setItem("_isBig", mqList.matches ? 1 : 0);
		}

		widthChangeCallback(mqList);
		mqList.addEventListener("change", widthChangeCallback);
	});
}

/**
 * 跳转至预约信息页面
 */
export function gotoReservation() {
	router.push('/Reservation')
}
export function openNewTab(path, query) {
	const routeUrl = router.resolve({
		path: path,
		query,
	});
	window.open(routeUrl.href, "_blank");
}

/**
 * 时间格式化: 时和分处理 12:30
 */
export function formatDateStr(str, d) {
	const date = d ? d : new Date(str);
	const hour = date.getHours();
	const min = date.getMinutes();
	let h = "";
	let m = "";
	if (hour < 10) {
		h += "0";
	}
	if (min < 10) {
		m += "0";
	}
	h += hour;
	m += min;
	return h + ":" + m;
}

/**
 * 时间格式化: 上午or下午
 */
export function formatDate(str, d) {
	if (!str == null && !d) return "";
	const date = d ? d : new Date(str);
	var hours = date.getHours() < 13 ? "上午" : "下午";
	return hours;
}

/**
 * 时间格式化: 周几?
 */
export function getWeekDay(str, d) {
	if (!str == null && !d) return "";
	const day = d ? d : new Date(str).getDay();
	return WEEKS[day];
}

/**
 * 日期信息:
 *  @params str: 字符串格式的日期
 *  @params d: 日期对象格式的日期
 *  @params SL: 日期连接符
 *  @return dateStr: 20**-**-**
 *  @return week:  周几
 *  @return hours:  小时
 *  @return minutes:  分钟
 *  @return seconds:  秒
 *  @return timeOf:  时段名称
 *
 */
export function getDateTimeInfo(str, SL = "-", d,) {
	if (!str && !d) return '';
	const date = d ? d : new Date(str);
	const year = date.getFullYear();
	const month =
		date.getMonth() + 1 < 10
			? "0" + (date.getMonth() + 1)
			: date.getMonth() + 1;
	const curDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

	const day = date.getDay();
	const hours =
		date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
	const minutes =
		date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
	const seconds =
		date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
	const week = WEEKS[day];
	let timeOf = "";
	const dateStr = `${year + SL + month + SL + curDate}`;
	if (hours < 6) {
		timeOf = "凌晨";
	} else if (hours < 9) {
		timeOf = "早上";
	} else if (hours < 12) {
		timeOf = "上午";
	} else if (hours < 14) {
		timeOf = "中午";
	} else if (hours < 17) {
		timeOf = "下午";
	} else if (hours < 19) {
		timeOf = "傍晚";
	} else if (hours < 22) {
		timeOf = "晚上";
	} else {
		timeOf = "深夜";
	}
	return {
		dateStr,
		week,
		hours,
		minutes,
		seconds,
		timeOf,
	};
}

/**
 * 获取图片背景色
 *  @params elName: 字符串格式的日期
 *  @params imgSrc: 日期对象格式的日期
 *  @return rgba: 图片主题rgba色值
 */
export function getImgThemeColor(imgSrc, elName) {
	const image = new Image();
	image.src = imgSrc;
	image.crossOrigin = "anonymous";
	image.onload = function () {
		const canvas = document.createElement("canvas");
		canvas.width = image.width;
		canvas.height = image.height;
		const context = canvas.getContext("2d");
		context.drawImage(image, 0, 0);
		const imageData = context.getImageData(
			0,
			0,
			image.width,
			image.height
		).data;
		const color = getColor(imageData);
		if (!elName) {
			return getColor(imageData);
		}
		const container = document.querySelector(elName);
		// console.log(container, '容器');
		container.style.backgroundColor = color;
		return getColor(imageData);
	};

	function getColor(imageData) {
		const rgb = { r: 0, g: 0, b: 0 };
		let count = 0;
		for (let i = 0; i < imageData.length; i += 4) {
			const r = imageData[i];
			const g = imageData[i + 1];
			const b = imageData[i + 2];
			const a = imageData[i + 3];
			if (a === 255) {
				rgb.r += r;
				rgb.g += g;
				rgb.b += b;
				count++;
			}
		}
		rgb.r = Math.round(rgb.r / count);
		rgb.g = Math.round(rgb.g / count);
		rgb.b = Math.round(rgb.b / count);
		// console.log(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`);
		return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2)`;
	}
}


export function autoScrollEleWidth(outerName, insideName){
	const outerW = document.querySelector(outerName)?.clientWidth;
	const insideEl = document.querySelector(insideName);
	const insideW = insideEl?.scrollWidth || 0;
	return { outerW, insideW, insideEl };
}

// 重写sessionStorage
export function dispatchEventStroage(key, newVal) {
	const signSetItem = sessionStorage.setItem
    sessionStorage.setItem = function (key, val) {
        let setEvent = new Event('setItemEvent')
        setEvent.key = key
        setEvent.newValue = val
        window.dispatchEvent(setEvent)
        signSetItem.apply(this, arguments)
    }
}


// 判断是否为微信浏览器
export function isWeixinBrowser() {
	let ua = navigator.userAgent.toLowerCase();
	return /micromessenger/.test(ua) ? true : false;
}

// 防抖
export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
 

 

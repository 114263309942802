import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { useScrollToTop  } from "@/hooks/useAnime.js";
import { nextTick } from 'vue';
const {NODE_ENV, VUE_APP_ROUTER_PATH: baseUrl, VUE_APP_ROUTER_MODE: mode, BASE_URL} = process.env || {};
const routes = [];


function asyncRoutes(){
    if(NODE_ENV === 'pro_prerender'){
		return require('./prerender-router')
    }
	return require('./lazy-router')
}
routes.push(...asyncRoutes().default)

console.log(BASE_URL)
const router = createRouter({
	history: mode ==='history' ? createWebHistory(baseUrl) : createWebHashHistory(baseUrl),
	// history: createWebHistory('./'),
	// history: createWebHistory(BASE_URL),
	routes
})
if(mode==='history'){
	router.beforeEach((to, from, next) => {
		console.log('history-beforeEach-fullPath:', from, to);
		if (to.fullPath.indexOf('/dist/index.html') > -1) {
			// 静态刷新情况 将html路径指向路由path
			next(to.fullPath.replace('/dist/index.html', ''))
			// next('/')
		}
		next();
	});
}
if(mode==='hash'){
	router.beforeEach((to, from, next) => {
		console.log('hash-beforeEach-fullPath:', from, to);
		if (to.fullPath.indexOf('/index.html') > -1) {
			next('/')
		}
		next();
	});
}
// 路由跳转完成
router.afterEach((to, from, next) => {
	nextTick(()=>{
		// 回页面顶部
		useScrollToTop()		
		// 预约信息页面隐藏快速进入按钮		
		if(to.path==='/Reservation'){
			document.querySelector('.toolbar-comp').style.display = 'none';
		}else{
			document.querySelector('.toolbar-comp').style.display = 'block';
		}
	})
});

export default router

// import anime from "animejs";
// import anime from "animejs/lib/anime.es.js";
import { Ref, onMounted } from "vue";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
/* easeInOutQuad  linear */
const EASING = "linear";

// /* 水平移动 */
// export function useTranslateX(opts) {
// 	const {
// 		el = null,
// 		from = 0,
// 		to = 0,
// 		duration = 200,
// 		delay = 0,
// 		...opotion
// 	} = opts || {};
// 		anime({
// 			targets: el,
// 			translateX: [from, to],
// 			// scale: [1.2, 1],
// 			// opacity: [0, 1],
// 			duration,
// 			delay,
// 			easing: EASING,
// 			...opotion,
// 		});
// }

// /* 垂直移动 */
// export function useTranslateY(
// 	el = null,
// 	from = 0,
// 	to = 0,
// 	delay = 0,
// 	duration = 200,
// 	opotion
// ) {
// 	onMounted(() => {
// 		anime({
// 			targets: el.value,
// 			translateY: [from, to],
// 			scale: [1.2, 1],
// 			opacity: [0, 1],
// 			duration,
// 			delay,
// 			easing: EASING,
// 			...opotion,
// 		});
// 	});
// }


// export function useAnime(opotion) {
// 	onMounted(() => {
// 		anime({
// 			...opotion,
// 			targets: opotion.targets.value,
// 		});
// 	});
// }



export function useGsapMoveX(opts) {
	const {
		el = null,
		x = "-=100",
		repeat = 0,
		duration = 5,
		ease = "none",
	} = opts || {};
	// console.log('opts', opts, el);
	return gsap.to(el, {
		x,
		duration,
		repeat,
		...opts,
		scrollTrigger: el,
	});
}


/* 滚筒文字效果 */
export function useGsapRollerText() {
	onMounted(() => {
		const tween = gsap.timeline({
			repeat: -1,
		});
		tween
			.to(".roller-text1",
				{ top: 0, opacity: 1, delay: 0, duration: 1 },
				"-=0.5"
			) // text1入场, 距前帧间隔-0.5
			.to(".roller-text1", { top: "-4.5rem", opacity: 0, duration: 1 }, "+=1") // text1出场, 距前帧间隔+1
			.fromTo( ".roller-text2", { top: "4.5rem", opacity: 1, }, { top: 0, duration: 1 }, "-=0.5" )	// text2入场, 距前帧间隔-0.5
			.to(".roller-text2", { top: "-4.5rem", opacity: 0, duration: 1, ease: 'power1.out' }, "+=1")	// text2出场, 距前帧间隔+1
			.fromTo( ".roller-text3", { top: "4.5rem", opacity: 1, }, { top: 0, duration: 1 }, "-=0.5" )	// text3入场, 距前帧间隔-0.5
			.to(".roller-text3", { top: "-4.5rem", opacity: 0, duration: 1 }, "+=1");  // text3出场, 距前帧间隔+1
	});
}


/* 滚动到顶部 */
export function useScrollToTop() {
	// onMounted(() => {
		gsap.to(window, { duration: 1, scrollTo: 0 });
		// gsap.to('body', {duration: 2, scrollTo: {y: "max"}});
	// });
}

/* 滚动到顶部ease */
export function useScrollToTopBy(opts) {
	// gsap.to(window, { duration: 1, scrollTo: { y: "max" } });
	const {
		el=window, 
		duration=2,
		scrollTo=0,
		ease="none"
	} = opts || {};
	gsap.to(el, {duration, scrollTo, ease, ...opts});
}


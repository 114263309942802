<template>

	<button
        v-if="showOpenBtn"
		type="button"
		class="btn btn-primary reservation-service-btn"
		data-toggle="modal"
		data-target="#reservationModal"
		data-whatever="@getbootstrap">
		预约服务
	</button>

	<div
		class="modal bd-example-modal-md fade reservation-service-modal"
		id="reservationModal"
		tabindex="-1"
		role="dialog"
        data-backdrop="static"
		aria-labelledby="exampleModalLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-body">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12 col-lg-6 d-none d-lg-block">
                                <!-- 背景填充... -->

                                <button
                                    type="button"
                                    class="btn btn-primary reservation-service-btn"
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    data-whatever="@getbootstrap">
                                    验证码
                                </button>

                            </div>
							<div class="col-12 col-lg-6">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">企业数字化转型加速器</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body-scroll">
                                    <div class="header-title">
                                        <h2>获取解决方案</h2>
                                        <p>感谢选择玲珑塔科技，我们会安排专业的顾问和您演示产品，以减少摸索软件的时间。 请填写下方信息，开启高效工作之道。</p>
                                    </div>
                                    <form ref="serviceForm" class="">
                                        <div class="form-group">
                                            <label
                                                for="name"
                                                class="col-form-label"></label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                :value="formData.name"
                                                @input="handlerInput($event, 'name')"
                                                placeholder="请输入您的姓名"
                                                ref="name"
                                                required
                                                id="name" />
                                            <div class="invalid-feedback"> 请输入您的姓名. </div>
                                        </div>
                                        <div class="form-group ">
                                            <label
                                                for="companyName"
                                                class="col-form-label"></label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                :value="formData.companyName"
                                                @input="handlerInput($event, 'companyName')"
                                                placeholder="请输入您的公司名称"
                                                required
                                                ref="companyName"
                                                id="companyName" />
                                            <div class="invalid-feedback"> {{ validator.companyName.validMsg }} </div>
                                        </div>
                                        <div class="form-group ">
                                            <label
                                                for="job"
                                                class="col-form-label"></label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                :value="formData.job"
                                                @input="handlerInput($event, 'job')"
                                                placeholder="请输入您的职务"
                                                required
                                                ref="job"
                                                id="job" />
                                            <div class="invalid-feedback"> 请输入您的职务. </div>
                                        </div>
                                        <div class="form-group">
                                            <label
                                                for="telphone"
                                                class="col-form-label"></label>
                                            <input
                                                type="tel"
                                                class="form-control"
                                                placeholder="请输入您的手机号码"
                                                ref="telphone"
                                                :value="formData.telphone"
                                                @input="handlerInput($event, 'telphone')"
                                                required
                                                id="telphone" />
                                            <div class="invalid-feedback"> {{ validator.telphone.validMsg }} </div>
                                        </div>

                                        <!-- 短信验证码1 -->
                                        <div class="form-group">
                                            <label
                                                for="verifyCode"
                                                class="col-form-label"></label>
                                            <div class="row">
                                                <div class="col">
                                                    <input
                                                        type="tel"
                                                        class="form-control"
                                                        placeholder="请输入验证码"
                                                        ref="verifyCode"
                                                        :value="formData.verifyCode"
                                                        @input="handlerInput($event, 'verifyCode')"
                                                        required
                                                        id="verifyCode" />
                                                    <div class="invalid-feedback"> 请输入验证码. </div>
                                                </div>
                                                <div class="col">
                                                    <button type="button" :disabled="vCode.disabled"  @click="sendVerifyCode">{{ vCode.text }}</button>
                                                </div>
                                            </div>
                                        </div>



                                        <!-- 短信验证码2 -->
                                        <div class="form-group">
                                            <label
                                                for="verifyCode"
                                                class="col-form-label"></label>
                                            <div class="input-group mb-3">
                                                <input
                                                type="tel"
                                                class="form-control"
                                                placeholder="请输入验证码"
                                                ref="verifyCode"
                                                :value="formData.verifyCode"
                                                @input="handlerInput($event, 'verifyCode')"
                                                required
                                                aria-label="请输入验证码" aria-describedby="basic-addon2"
                                                id="verifyCode" />
                                                <div class="input-group-append" @click="countdownHandler">
                                                    <span class="input-group-text sendVerifyCode" id="basic-addon2">发送验证码</span>
                                                </div>
                                                <div class="invalid-feedback"> 请输入验证码. </div>
                                            </div>
                                        </div>

                                    </form>
                                    <div class="form-group">
                                        <label for="message" class="col-form-label">留言</label>
                                        <textarea
                                            class="form-control"
                                            placeholder="请输入您的留言信息"
                                            :value="formData.message"
                                            @input="handlerInput($event, 'message')"
                                            ref="message"
                                            id="message"></textarea>
                                        <div class="invalid-feedback"> 请留言. </div>
                                    </div>
                                    <div class="form-btn text-center">
                                        <button class="btn btn-primary" @click="handlerSubmit">提 交2</button>
                                    </div>
                                </div>
							</div>
						</div>

					</div>

				</div>
				<!-- <div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-dismiss="modal">
						Close
					</button>
					<button type="button" class="btn btn-primary">
						Send message
					</button>
				</div> -->
			</div>
		</div>
	</div>



    <!--  验证码 Modal -->
    <div class="modal fade verify-image-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">请完成安全验证</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="image">
                        <img src="" alt="">
                    </div>
                    <div class="drag">

                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, getCurrentInstance, watch, defineEmits } from 'vue';
const emit = defineEmits(['update:show']);

const props = defineProps({
	show: {     // 弹框是否打开
		type: Boolean,
		default: true,
    },
	showOpenBtn: {  // 是否使用内部打开弹框按钮
		type: Boolean,
		default: false,
	},
})
// 监听
watch(() => props.show, (n, o) => {
    // console.log(props.show);
    $('#reservationModal').modal(props.show ? 'show' : 'hide')
}, { immediate: true });

onMounted(() => {
    // 弹框关闭前
    $('#reservationModal').on('hide.bs.modal', function (e) {
        emit('update:show', false);
    })
    // 弹框打开前
    $('#reservationModal').on('show.bs.modal', function (e) {
        emit('update:show', true);
    })
})

const { proxy } = getCurrentInstance()
const formData = reactive({
    name: '',           // 姓名
    companyName: '',        // 公司名称
    job: '',           // 职务
    telphone: '',       // 联系电话
    message: '',        // 留言
    verifyCode: '',        // 验证码
})

const validator = reactive({
    name: {
        validMsg: '请输入您的姓名.',
        require: true,
    },
    job: {
        validMsg: '请输入您的职务.',
        require: true,
    },
    verifyCode: {
        validMsg: '请输入验证码.',
        require: true,
    },
    companyName: {
        isValid: function(val) {
            console.log(this, val);
            if (!val) {
                this.validMsg = '请输入你的公司名称.'
                return false;
            } else {
                this.validMsg = ''
                return true;
            }
        },
        validMsg: ''
    },
    telphone: {
        isValid: function(val, state) {
            console.log(this, val);
            // var phone = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/; 
            let phone = /^1[3-9][0-9]{9}$/;
            console.log(phone.test(val), '手机校验');
            if (!val) {
                this.validMsg = '请输入你的手机号码.'
                return false;
            } else if(val){//手机号码
                this.validMsg = phone.test(val) ? '' : '请输入有效的手机号码.';
                return phone.test(val);
            } else {
                this.validMsg = ''
                return true;
            }
        },
        validMsg: ''
    }
})
/* 输入事件监听 */
const handlerInput = (e, form) => {
    formData[form] = e.target.value;
    // 自定义校验
    if (!validator[form].isValid) return;
    const isValid = validator[form].isValid(formData[form], 'inputing');
    const validMsg = validator[form].validMsg;
    proxy.$refs[form].setCustomValidity(validMsg);
}
const serviceForm = ref(null);

/* 点击提交 */
const handlerSubmit = () => {
    console.log('checkValid', checkValid())
    if (!checkValid()) return;  
    // 如果校验通过
    console.log(formData, 'formData');

    // 提交完成: 重置校验状态并关闭弹窗
    serviceForm.value.classList.remove('was-validated');
    $('#reservationModal').modal(props.show ? 'show' : 'hide')
    
    return;
    // 发送接口
    // const url = process.env.VUE_APP_BASE_API + `/topone/article/index`; // 接口地址
	// const params = JSON.stringify(formData);
	// $.ajax({
	// 	url: url,
	// 	type: "POST",
	// 	data: params,
	// 	success: function (res) {
    //         if (res.code !== 200 || !res.data) return;
            
	// 	}
    // });
}
function checkValid() {
    console.log(serviceForm.value.classList, 'ppppppppp');
    const validErr = Array.prototype.filter.call(Object.keys(validator), function(form) {
        console.log(form, '表单项bs必填结果:', proxy.$refs[form].checkValidity());
        let isValid = true;
        if (validator[form].isValid) {  // 自定义校验程序
            const isValid = validator[form].isValid(formData[form]);
            proxy.$refs[form].setCustomValidity(validator[form].validMsg);
        }
        /* was-validated needs-validation   */
        serviceForm.value.classList.add('was-validated');
        return !isValid || !proxy.$refs[form].checkValidity();
    });
    console.log('error项', validErr)
    return validErr.length === 0;
}


//短信验证码功能
const vCode = reactive({
    number: 60,     // 倒计时秒数
    disabled: false,    // 计时中禁用状态
    text: '发送验证码1',
})

/* 点击发送验证码 */
const sendVerifyCode = () => {
    // 发送接口
    // const url = process.env.VUE_APP_BASE_API + `/topone/article/index`; // 接口地址
	// const params = JSON.stringify({ categoryVoList: [{ num: 3, otherName: 'bpnews' }] });
	// $.ajax({
	// 	url: url,
	// 	type: "POST",
	// 	data: params,
	// 	success: function (res) {
	// 		if (res.code !== 200 || !res.data) return;
    //         countdownHandle();
	// 	}
    // });
    countdownHandle();
}

/* 验证码倒计时计算 */
var countdownHandle = function () {
    if (vCode.disabled) return;
    vCode.number = 60;
    var countdown = function () {
        if (vCode.number == 0) {
            vCode.disabled = false;
            vCode.text = '发送验证码';
            vCode.number = 60;
            return;
        } else {
            vCode.disabled = true;
            vCode.text =  vCode.number + "秒 重新发送";
            vCode.number--;
        }
        setTimeout(countdown, 1000);
    }
    setTimeout(countdown, 1000);
}
var countdownHandler = function () {
    var $button = $(".sendVerifyCode");
    var number = 60;
    var countdown = function () {
        if (number == 0) {
            $button.attr("disabled", false);
            $button.html("发送验证码");
            number = 60;
            return;
        } else {
            $button.attr("disabled", true);
            $button.html(number + "秒 重新发送");
            number--;
        }
        setTimeout(countdown, 1000);
    }
    setTimeout(countdown, 1000);
}

onMounted(() => {    
})
</script>

<style lang="scss" scoped>
.reservation-service-btn {
}
.reservation-service-modal {
}

.modal-body-scroll{
    height: 70vh !important;
    overflow-y: auto;
}

.verify-image-modal{
    .modal-content{
        width: 500px;

    }
    .modal-body{

    }
}

</style>

import HomeFooter from '@/components/HomeFooter.vue';
import ReservationService from "@/components/ReservationService.vue";
import ArrSvg from "@/components/ArrSvg.vue";

const components = {
    HomeFooter,
    ReservationService,
    ArrSvg
};
export default {
    install(app) {
        Object.keys(components).forEach((key) => {
            app.component(key, components[key]);
        });
    },
};
